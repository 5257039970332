.step-1 {
  .green, .red, .purple {
    display: flex;
    //justify-content: space-between;

    height: 100px;
  }

  .one, .two, .three {
    border: 1px black solid;
    flex-grow: 1;
  }

  .green {
    //.one, .two, .three {
    //  background-color: green;
    //}
    .one {
      background-image: linear-gradient(0deg, green 27.7%, #ffffff 27.7%, #ffffff 50%, green 50%, green 77.7%, #ffffff 77.7%, #ffffff 100%);
      background-size: 278.00px 135px;
    }

    .two {
      background-image: linear-gradient(0deg, green 22.78%, #ffffff 22.78%, #ffffff 50%, green 50%, green 72.78%, #ffffff 72.78%, #ffffff 100%);
      background-size: 158.00px 77px;
    }

    .three {
      background-image: linear-gradient(0deg, green 30.51%, #ffffff 30.51%, #ffffff 50%, green 50%, green 80.51%, #ffffff 80.51%, #ffffff 100%);
      background-size: 118.00px 58px;
    }
  }

  .red {
    .one {
      background-image: linear-gradient(0deg, red 27.7%, #ffffff 27.7%, #ffffff 50%, red 50%, red 77.7%, #ffffff 77.7%, #ffffff 100%);
      background-size: 278.00px 135px;
    }

    .two {
      background-image: linear-gradient(0deg, red 22.78%, #ffffff 22.78%, #ffffff 50%, red 50%, red 72.78%, #ffffff 72.78%, #ffffff 100%);
      background-size: 158.00px 77px;
    }

    .three {
      background-image: linear-gradient(0deg, red 30.51%, #ffffff 30.51%, #ffffff 50%, red 50%, red 80.51%, #ffffff 80.51%, #ffffff 100%);
      background-size: 118.00px 58px;
    }
  }

  .purple {
    .one {
      background-image: linear-gradient(0deg, purple 27.7%, #ffffff 27.7%, #ffffff 50%, purple 50%, purple 77.7%, #ffffff 77.7%, #ffffff 100%);
      background-size: 278.00px 135px;
    }

    .two {
      background-image: linear-gradient(0deg, purple 22.78%, #ffffff 22.78%, #ffffff 50%, purple 50%, purple 72.78%, #ffffff 72.78%, #ffffff 100%);
      background-size: 158.00px 77px;
    }

    .three {
      background-image: linear-gradient(0deg, purple 30.51%, #ffffff 30.51%, #ffffff 50%, purple 50%, purple 80.51%, #ffffff 80.51%, #ffffff 100%);
      background-size: 118.00px 58px;
    }
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  //flex-direction: row;

  .card-wrapper {
    margin: 10px;

    .button-wrapper {
      display: flex;
      justify-content: center;
    }

    .card {
      min-height: 190px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #b7b7b7;

      .empty, .stripe, .full {
        background-size: cover;
        width: 100px;
        height: 50px;
      }

      .symbol {
        margin: 5px;

        .red {
          &.diamond {
            &.empty {
              background-image: url('./images/cards/red-diamond-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/red-diamond-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/red-diamond-full.jpg')
            }
          }

          &.wave {
            &.empty {
              background-image: url('./images/cards/red-wave-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/red-wave-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/red-wave-full.jpg')
            }
          }

          &.oval {
            &.empty {
              background-image: url('./images/cards/red-oval-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/red-oval-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/red-oval-full.jpg')
            }
          }
        }

        .green {
          &.diamond {
            &.empty {
              background-image: url('./images/cards/green-diamond-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/green-diamond-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/green-diamond-full.jpg')
            }
          }

          &.wave {
            &.empty {
              background-image: url('./images/cards/green-wave-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/green-wave-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/green-wave-full.jpg')
            }
          }

          &.oval {
            &.empty {
              background-image: url('./images/cards/green-oval-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/green-oval-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/green-oval-full.jpg')
            }
          }
        }

        .purple {
          &.diamond {
            &.empty {
              background-image: url('./images/cards/purple-diamond-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/purple-diamond-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/purple-diamond-full.jpg')
            }
          }

          &.wave {
            &.empty {
              background-image: url('./images/cards/purple-wave-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/purple-wave-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/purple-wave-full.jpg')
            }
          }

          &.oval {
            &.empty {
              background-image: url('./images/cards/purple-oval-empty.jpg')
            }

            &.stripe {
              background-image: url('./images/cards/purple-oval-stripe.jpg')
            }

            &.full {
              background-image: url('./images/cards/purple-oval-full.jpg')
            }
          }
        }
      }
    }
  }
}